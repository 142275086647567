import React, {useContext, useEffect, useState} from "react";
import {GridColDef, GridRowParams} from "@mui/x-data-grid/models";
import {NetfilterCustomerConfig} from "../../interfaces/INetfilterCustomerConfig";
import {EbAuthenticatedAppContext} from "@eidsivabredband/app-framework";
import {DataGrid} from "@mui/x-data-grid";
import {EditCustomerConfig} from "../netfilter-customer-config/EditCustomerConfig";
import {Check, Clear} from "@mui/icons-material";

const columnDefinitions = () => {
    return [
        {
            field: 'connectionNumber',
            headerName: 'Sambandsnummer',
            width: 150
        },
        {
            field: 'customerNumber',
            headerName: 'Kundenummer',
            width: 130
        },
        {
            field: 'accessId',
            headerName: 'AdresseId',
            width: 100
        },
        {
            field: 'userName',
            headerName: 'Portinfo',
            width: 190
        },
        {
            field: 'webFilter',
            headerName: 'Web-filter',
            width: 120,
            renderCell: (params: any) => {
                return params.row.webFilter ? <Check color={"primary"}/> : null;
            }
        },
        {
            field: 'dnsFilter',
            headerName: 'DNS-filter',
            width: 120,
            renderCell: (params: any) => {
                return params.row.dnsFilter ?<Check color={"primary"}/> : null;
            }
        },
        {
            field: 'ips',
            headerName: 'IPS-filter',
            width: 120,
            renderCell: (params: any) => {
                return params.row.ips ? <Check color={"primary"}/> : null;
            }
        },
        {
            field: 'portForwardingSummary',
            headerName: 'Åpne porter',
            width: 300
        }
    ];
}

const summarizePortForwarding = (netfilterCustomerConfig: NetfilterCustomerConfig) => {
    if (netfilterCustomerConfig.portForwardingAll) {
        return "Alle";
    }

    const portForwardingSummary = new Array<string>;

    if (netfilterCustomerConfig.portForwardingPing) portForwardingSummary.push("Ping");
    if (netfilterCustomerConfig.portForwardingHttps) portForwardingSummary.push("Https");
    if (netfilterCustomerConfig.portForwardingSsh) portForwardingSummary.push("Ssh");
    if (netfilterCustomerConfig.portForwardingFgfm) portForwardingSummary.push("Fgfm");
    if (netfilterCustomerConfig.portForwardingIke) portForwardingSummary.push("Ike");
    if (netfilterCustomerConfig.portForwardingInteno) portForwardingSummary.push("Inteno");
    if (netfilterCustomerConfig.portForwardingSummary) portForwardingSummary.push("Summary");

    return portForwardingSummary.join(", ");
}

export const NetfilterOverview = () => {
    const [netfilterCustomerConfigs, setNetfilterCustomerConfigs] = useState<NetfilterCustomerConfig[]>([]);
    const [netfilterCustomerConfigToEdit, setNetfilterCustomerConfigToEdit] = useState<NetfilterCustomerConfig>();
    const [showEditSubscription, setShowEditSubscriptions] = useState<boolean>(false);
    const {context} = useContext(EbAuthenticatedAppContext)

    const editSubscription = (netfilterCustomerConfig: NetfilterCustomerConfig) => {
        setNetfilterCustomerConfigToEdit(netfilterCustomerConfig);
        setShowEditSubscriptions(true)
    }

    const handleCloseEditSubscription = () => {
        setShowEditSubscriptions(false);
    }

    useEffect(() => {
        let i = 0;
        context.restApiClient.buildRequest('/api/netfilter/customer-configs').deserializeResponseToJson<Array<NetfilterCustomerConfig>>().getAsync()
            .then((response) => {
                if (response.json) {
                    setNetfilterCustomerConfigs(response.json.map(x => {
                        return {...x, id: i++, portForwardingSummary: summarizePortForwarding(x)}
                    }));
                }
               
            });
    }, [context.restApiClient]);
    
    const updateNetfilterCustomerConfigs = (netfilterCustomerConfig: NetfilterCustomerConfig) => {
        setNetfilterCustomerConfigs(netfilterCustomerConfigs.map(x => {           
            if (x.id === undefined || netfilterCustomerConfig.id === undefined) {       //If either id is undefined, throw an error as this should never happen
                throw new Error("Id is undefined");
            } else if(x.id === netfilterCustomerConfig.id) {                            
                return {
                    ...netfilterCustomerConfig,
                    portForwardingSummary: summarizePortForwarding(netfilterCustomerConfig)
                }
            } else {
                return x;
            }
        }))};

    const columns: GridColDef[] = columnDefinitions()

    return (
        <>
            {netfilterCustomerConfigs &&
                <DataGrid
                    onRowClick={(params: GridRowParams) => editSubscription(params.row)}
                    columns={columns}
                    // getRowId={(row) => row.id}
                    rows={netfilterCustomerConfigs}
                />}

            {showEditSubscription && netfilterCustomerConfigToEdit &&
                <EditCustomerConfig
                    onClose={handleCloseEditSubscription}
                    netfilterCustomerConfig={netfilterCustomerConfigToEdit}
                    setNetfilterCustomerConfig={setNetfilterCustomerConfigToEdit}
                    handleUpdateNetfilterUpdated={updateNetfilterCustomerConfigs}
                />
            }
        </>
    )
}
