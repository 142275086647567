import React, {useContext} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography} from "@mui/material";
import {NetfilterCustomerConfig} from "../../interfaces/INetfilterCustomerConfig";
import {EbCheckbox, SectionHeader, useSnackBar} from "@eidsivabredband/react-component-ui-package";
import {EbAuthenticatedAppContext} from "@eidsivabredband/app-framework";
import {ReactComponent as RouterIcon} from '../../assets/illustrations/Bredbånd/ruter.svg';
import {ReactComponent as FirewallIcon} from '../../assets/illustrations/Bredbånd/brannmur.svg';
import {theme} from "../../assets/theme";

interface EditSubscriptionProps {
    onClose: () => void;
    netfilterCustomerConfig: NetfilterCustomerConfig;
    setNetfilterCustomerConfig: (netfilterCustomerConfig: NetfilterCustomerConfig) => void;
    handleUpdateNetfilterUpdated: (netfilterCustomerConfig: NetfilterCustomerConfig) => void;
}

export const EditCustomerConfig = ({netfilterCustomerConfig,
                                       onClose,
                                       setNetfilterCustomerConfig,
                                       handleUpdateNetfilterUpdated}: EditSubscriptionProps) => {
    const {context} = useContext(EbAuthenticatedAppContext)
    const showSnackbar = useSnackBar();

    const toggleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        let updatedNetfilterCustomerConfig = { ...netfilterCustomerConfig, [event.target.name]: event.target.checked};
        if (event.target.name === "portForwardingAll") {
            updatedNetfilterCustomerConfig = {
                ...updatedNetfilterCustomerConfig,
                portForwardingPing: false,
                portForwardingHttps: false,
                portForwardingSsh: false,
                portForwardingFgfm: false,
                portForwardingIke: false,
                portForwardingInteno: false
            }
        } else if (event.target.name.startsWith("portForwarding")) {
            updatedNetfilterCustomerConfig = {
                ...updatedNetfilterCustomerConfig,
                portForwardingAll: false
            }
        }

        setNetfilterCustomerConfig({
            ...updatedNetfilterCustomerConfig
        })
    }

    return (
        <Dialog open={true} fullWidth onClose={onClose}>
            <DialogTitle  variant={"h4"}>
                Endre detaljer
            </DialogTitle>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    context.restApiClient.buildRequest(`/api/netfilter/customer-config/${netfilterCustomerConfig.customerNumber}`)
                        .serializeToJsonContent(netfilterCustomerConfig).deserializeResponseToJson<NetfilterCustomerConfig>().putAsync().then((response) => {
                        if (response.response?.status === 200 && response.json) {
                                showSnackbar('Netfilter-konfigurasjonen ble oppdatert', 'success');
                                
                                // If the response doesn't cotain a row id, insert the one from the original object
                                let responseJson = response.json;
                                if (responseJson.id === undefined) {
                                    responseJson = {...responseJson, id: netfilterCustomerConfig.id}
                                }

                                handleUpdateNetfilterUpdated(responseJson);
                                onClose();
                            } else {
                                showSnackbar(response.error?.errorCode + ' - ' + response.error?.errorText + ': Noe gikk galt under oppdatering av netfilter-konfigurasjonen. Se konsoll for mer informasjon.', 'error');
                            }
                    });
                }}
            >
                <DialogContent dividers={true}>
                    <Grid container spacing={2}>
                        <Grid item md={12} left-margin={3} width={"100%"}>
                            <Box display={"flex"} justifyContent={"center"} gap={4} flexWrap={"wrap"} sx={{p:2}}>
                                <div>
                                    <Typography variant={"caption"}>Sambandsnummer</Typography>
                                    <Typography variant={"h6"}>{netfilterCustomerConfig.connectionNumber}</Typography>
                                </div>
                                <div>
                                    <Typography variant={"caption"}>Kundenummer</Typography>
                                    <Typography variant={"h6"}>{netfilterCustomerConfig.customerNumber}</Typography>
                                </div>
                                <div>
                                    <Typography variant={"caption"}>Aksess ID</Typography>
                                    <Typography variant={"h6"}>{netfilterCustomerConfig.accessId}</Typography>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item md={12} width={"100%"}>

                            <Divider/>
                        </Grid>
                        <Grid item md={12} left-margin={3} >
                            <SectionHeader title={"Filtre"} size={"SMALL"} icon={<RouterIcon/>} circleColor={theme.palette.primary.light}/>
                            <Box sx={{pr: 4, pl:4}}>
                                <EbCheckbox label="Web-filter" name="webFilter" checked={netfilterCustomerConfig.webFilter} onChange={toggleCheck}/>
                                <EbCheckbox label="DNS-filter" name="dnsFilter" checked={netfilterCustomerConfig.dnsFilter} onChange={toggleCheck}/>
                                <EbCheckbox label="IPS-filter" name="ips" checked={netfilterCustomerConfig.ips} onChange={toggleCheck}/>
                            </Box>
                        </Grid>
                        <Grid item md={12} padding={4}>
                            <SectionHeader title={"Åpne porter"} size={"SMALL"} icon={<FirewallIcon/>} circleColor={theme.palette.primary.light}/>
                            <Box  sx={{pr: 4, pl: 4, mb: 2}}>
                                <EbCheckbox label="Alle"
                                            name="portForwardingAll"
                                            onChange={toggleCheck}
                                            checked={netfilterCustomerConfig.portForwardingAll}
                                />
                            </Box>
                            <Box sx={{pr: 4, pl:4}}>
                                <EbCheckbox label="Ping"
                                            name="portForwardingPing"
                                            onChange={toggleCheck}
                                            checked={netfilterCustomerConfig.portForwardingPing}
                                />
                                <EbCheckbox label="IKE"
                                            name="portForwardingIke"
                                            onChange={toggleCheck}
                                            checked={netfilterCustomerConfig.portForwardingIke}
                                />
                                <EbCheckbox label="Https"
                                            name="portForwardingHttps"
                                            onChange={toggleCheck}
                                            checked={netfilterCustomerConfig.portForwardingHttps}
                                />
                                <EbCheckbox label="Inteno"
                                            name="portForwardingInteno"
                                            onChange={toggleCheck}
                                            checked={netfilterCustomerConfig.portForwardingInteno}
                                />
                                <EbCheckbox label="SSH"
                                            name="portForwardingSsh"
                                            onChange={toggleCheck}
                                            checked={netfilterCustomerConfig.portForwardingSsh}
                                />
                                <EbCheckbox label="FGFM"
                                            name="portForwardingFgfm"
                                            onChange={toggleCheck}
                                            checked={netfilterCustomerConfig.portForwardingFgfm}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained">Avbryt</Button>
                    <Button type="submit"  variant="contained">Lagre</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}