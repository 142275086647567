import {Link, Outlet, Route, Routes} from 'react-router-dom';

// mui
import {Container, Tab} from '@mui/material';
import {Banner, ErrorWrapper, Header, MyAccountMenu} from "@eidsivabredband/react-component-ui-package";
import React, {useContext} from "react";
import {EbAuthenticatedAppContext, useAuthentication} from "@eidsivabredband/app-framework";

interface LogMessage {
    message: string;
    sourceClass?: string;
    sourceMethod?: string;
    sourceLineNumber?: string;
}

type LogLevel = 'Info' | 'Error' | 'Warning';
export const Layout = () => {
    const {context} = useContext(EbAuthenticatedAppContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleReset = () => {
        // reset the application state (e.g. reload or reset state)
    };

    const handleError = (error: Error, info: { componentStack: string }) => {
        const {message, stack} = error;
        if (stack) {
            const logMessage: LogMessage = {message: stack};
            const logLevel: LogLevel = 'Error';

            context.restApiClient
                .putApiAnonymously(`/api/log/${logLevel}`, logMessage)
                .then((response: any) => {
                    console.log(response);
                });
        }
    };

    const client = useAuthentication();
    const handleSignIn = () => {
        if (!context) return;
        client.login(context.authConfig?.scopes || []);
    };

    const handleSignOut = () => {
        if (!context) return;
        client.logout(context.idToken || '');
    };

    return (
        <>
            <Header>
                <MyAccountMenu
                    isAuthenticated={!!context.idToken}
                    firstName={client.getActiveUser().idTokenClaims?.given_name}
                    lastName={client.getActiveUser().idTokenClaims?.family_name}
                    email={client.getActiveUser().idTokenClaims?.emails?.[0]}
                    handleSignIn={handleSignIn}
                    handleSignOut={handleSignOut}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                />
            </Header>
            <Container sx={{mt: 5, mb: 5}} maxWidth="xl">
                <ErrorWrapper handleError={handleError} handleReset={handleReset}>
                    <Outlet/>
                </ErrorWrapper>
            </Container>
        </>
    )
};
    
