import React from 'react';
import './index.css';
import {
    AuthenticatedAppProvider,
    ConsoleLogger,
    EbAuthenticatedContext,
    IAuthenticationConfig
} from '@eidsivabredband/app-framework';
import {App} from './App';
import {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@mui/material';
import {theme} from './assets/theme';
import {BrowserRouter} from "react-router-dom";
import {SnackBarProvider} from "@eidsivabredband/react-component-ui-package";

const container = document.getElementById('root');
const root = createRoot(container!);

fetch('/api/auth-config').then(async (response: Response) => {
    const json = await response.json();
    const config = json as IAuthenticationConfig;

    root.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <AuthenticatedAppProvider logger={new ConsoleLogger()} authConfig={config}>
                    <SnackBarProvider>
                        <EbAuthenticatedContext>
                            <BrowserRouter>
                                <App/>
                            </BrowserRouter>
                        </EbAuthenticatedContext>
                    </SnackBarProvider>
                </AuthenticatedAppProvider>
            </ThemeProvider>
        </React.StrictMode>
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
