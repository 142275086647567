import { createTheme } from '@mui/material/styles';
import { nbNO } from '@mui/material/locale';

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        accent: Palette['primary'];
    }
    interface PaletteOptions {
        accent: PaletteOptions['primary'];
    }
}

// EB Light Theme
export const theme = createTheme(
    {
        palette: {
            primary: {
                light: '#82c0f4',
                main: '#3a41ff',
                dark: '#2c31bf',
            },
            secondary: {
                /* light: '#d3fffa', */
                light: '#19bfac',
                main: '#19bfac',
                dark: '#118073',
            },
            text: {
                primary: '#101820',
                secondary: '#40464D',
                disabled: '#0000008c',
            },
            background: {
                default: '#FFFFFF',
                paper: '#F7F7FF',
            },
            accent: {
                main: '#00069B',
                light: '#B3B4E1',
                dark: '#000489',
            },
            success: {
                main: '#21ac04',
                light: '#7acd68',
                dark: '#198103',
            },
            warning: {
                main: '#e17000',
                light: '#eda966',
                dark: '#a95400',
            },
            error: {
                main: '#cb0000',
                light: '#e06666',
                dark: '#980000',
            },
            info: {
                light: '#d3a7ff',
                main: '#1779FF',
                dark: '#0C3D80',
            },
        },
        typography: {
            fontFamily: "'Aeonik', 'Poppins', 'Arial'",
            fontSize: 16,
            h1: {
                fontFamily: "'Aeonik', 'Poppins'",
                fontSize: '5rem',
                lineHeight: '125%',
            },
            h2: {
                fontFamily: "'Aeonik', 'Poppins'",
                fontSize: '3rem',
                lineHeight: '125%',
            },
            h3: {
                fontFamily: "'Aeonik', 'Poppins'",
                fontSize: '2.5rem',
                lineHeight: '125%',
            },
            h4: {
                fontFamily: "'Aeonik', 'Poppins'",
                fontSize: '1.75rem',
            },
            h5: {
                fontFamily: "'Aeonik', 'Poppins'",
                fontSize: '1.5rem',
                lineHeight: '150%',
            },
            h6: {
                fontFamily: "'Aeonik', 'Poppins'",
                fontSize: '1.15rem',
                lineHeight: '150%',
            },
            button: {
                fontFamily: "'Aeonik', 'Poppins'",
                textTransform: 'none',
            },
            subtitle1: {
                fontFamily: "'Aeonik Bold', 'Poppins'",
                fontSize: '1.35rem',
                lineHeight: '150%',
            },
            subtitle2: {
                fontFamily: "'Aeonik Bold', 'Poppins'",
                fontSize: '1.35rem',
                lineHeight: '150%',
                textTransform: 'uppercase',
            },
            body1: {
                lineHeight: '150%',
            },
            body2: {
                lineHeight: '150%',
            },
            caption: {
                fontSize: '0.9rem',
                lineHeight: '150%',
            },
            overline: {
                fontFamily: 'Aeonik Light',
                lineHeight: '150%',
            },
        },
        shape: {
            borderRadius: 16,
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        padding: '6px 16px',
                        borderRadius: '12px',
                    },
                    outlined: {
                        borderWidth: 1.5,
                        '&:hover': {
                            borderWidth: 1.5,
                        },
                    },
                    textPrimary: {
                        backgroundColor: '#82c0f440', // .25 % opacity
                        '&:hover': {
                            backgroundColor: '#3d86fc80', // .50 % opacity
                        }
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        display: 'inline-flex',
                        textUnderlinePosition: 'under',
                        textUnderlineOffset: 0.5,
                        textDecorationThickness: 1.5,
                        textDecorationColor: 'transparent',
                        transition: 'all 0.2s ease-in',
                        fontFamily: "'Aeonik', 'Poppins'",
                    },
                    underlineHover: {
                        '&:hover': {
                            textDecorationColor: '#3a41ff',
                            textUnderlinePosition: 'under',
                            textUnderlineOffset: 0.5,
                            textDecorationThickness: 1.25,
                        },
                    },
                    underlineAlways: {
                        textDecorationColor: '#82c0f4',
                        '&:hover': {
                            textDecorationColor: '#3a41ff',
                            textUnderlinePosition: 'under',
                            textUnderlineOffset: 0.5,
                            textDecorationThickness: 1.5,
                        },
                    },
                },
            },
        },
    },
    nbNO
);
