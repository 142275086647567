import {Route, Routes} from 'react-router-dom';
import React from "react";
import {Layout} from './Layout';
import {NetfilterOverview} from "./views/netfilter-overview/NetfilterOverview";

export const App = () => {
    return (
        <Routes>
            <Route element={<Layout/>}>
                <Route index element={<NetfilterOverview/>}/>
                <Route path="logout-callback" element={<div />}/>
            </Route>
        </Routes>
    );
};
